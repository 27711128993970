import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Main } from "page_components/apartment"

const Apartment = ({ pageContext }) => {
  return (
    <Layout seo={{ title: pageContext?.data?.title }} isApartment>
      <Breadcrumbs
        title={pageContext?.data?.title}
        parent={{ title: "Mieszkania", url: "/mieszkania/" }}
      />
      <PageHeader title={pageContext?.data?.title} />
      <Main data={pageContext?.data?.data} />
    </Layout>
  )
}

export default Apartment
